import React from "react";
import { Dropdown } from "react-bootstrap";
import storage from "../../services/storage";

const HeaderApps = () => {
  const changeApp = (link) => {
    const currentDomain = window.location.hostname;
    const targetDomain = new URL(link).hostname;
    if (currentDomain === targetDomain) {
      return false;
    }
    window.open(
      link + "/redirect?data=" + storage.get("access_token"),
      "_blank"
    );
  };

  return (
    <div>
      <Dropdown className="ms-1 topbar-head-dropdown header-item">
        <Dropdown.Toggle
          variant=""
          className="dropdown d-flex btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bxs-grid-alt fs-22"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0" align="end">
          <Dropdown.Item
            as="button"
            onClick={() => {
              changeApp("https://chat.otokata.id");
            }}
          >
            <span className="align-middle">Otochat</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderApps;
