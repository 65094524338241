import { useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { RiDeleteBinFill } from "react-icons/ri";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import { SessionContext } from "../provider/sessionContext";
import axios from "axios";
import ai_light from "../assets/images/r-light.gif";
import ai_dark from "../assets/images/r-dark.gif";
import { ThemeContext } from "../provider/themeContext";

const ModalRemoveSession = ({ item }) => {
  const {
    setSessionList,
    setSelectedSession,
    setActiveSession,
    setActiveMessage,
    setMessages,
  } = useContext(SessionContext);
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    setLoading(true);
    axios
      .post(
        API_URL + "remove-session-chat",
        {
          idt_session: item.idt_session,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setSessionList((prevSession) => {
            return prevSession.filter(
              (session) => session.idt_session !== item.idt_session
            );
          });
          setSelectedSession(null);
          setActiveSession(false);
          setActiveMessage(false);
          setMessages([]);
          setLoading(false);
          handleClose();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        className="btn c-icon mt-n1 p-0"
        role="button"
        variant="none"
        onClick={handleShow}
      >
        <RiDeleteBinFill size={20} />
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-danger-subtle" closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="bg-ai">
          <div className="mt-2 text-center">
            <img src={theme === "dark" ? ai_dark : ai_light} />
            {/* <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: 80, height: 80 }}
            ></lord-icon> */}
            <div className="mt-1 pt-2 fs-15 mx-4 mx-sm-5">
              <h5 className="text-truncate">Session: {item.title}</h5>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to remove ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button className="btn w-sm" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="btn w-sm"
              variant="danger"
              disabled={loading}
              onClick={handleDelete}
            >
              {loading ? (
                <>
                  <Spinner
                    className="flex-shrink-0"
                    animation="border"
                    size="sm"
                  />
                </>
              ) : (
                "Remove"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRemoveSession;
