import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import qris from "../../assets/images/payment/qris.png";
import shopay from "../../assets/images/payment/shopay.png";
import ovo from "../../assets/images/payment/ovo.png";
import gopay from "../../assets/images/payment/gopay.png";
import dana from "../../assets/images/payment/dana.png";
import bni from "../../assets/images/payment/BNI.png";
import bca from "../../assets/images/payment/BCA.png";
import bri from "../../assets/images/payment/BRI.png";
import mandiri from "../../assets/images/payment/mandiri.png";
import payment_img from "../../assets/images/payment.png";
import { formatText, format_number } from "../../utilities/ext-function";
import authService from "../../services/auth.service";
import axios from "axios";
import authHeader from "../../services/auth.header";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import Skeleton from "react-loading-skeleton";
import { MdOutlinePayments } from "react-icons/md";
import { CreditContext } from "../../provider/creditContext";
// import { SubscriptionContext } from "../../provider/subscriptionContext";

const TransactionStatus = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { getBalance } = useContext(CreditContext);
  // const { getSubs } = useContext(SubscriptionContext);
  const [transaction, setTransaction] = useState(null);

  const items = [
    { value: "qris", image: qris },
    { value: "shopee_pay", image: shopay },
    { value: "gopay", image: gopay },
    { value: "ovo", image: ovo },
    { value: "dana", image: dana },
    { value: "bni", image: bni },
    { value: "bca", image: bca },
    { value: "bri", image: bri },
    { value: "mandiri", image: mandiri },
  ];

  useEffect(() => {
    axios
      .post(
        API_URL + "get-transaction",
        {
          oid: orderId,
          status: "all",
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          if (response.data.data.status === "success") {
            getBalance();
            // getSubs();
          }
          setTransaction(response.data.data);
        } else {
          navigate("/account?tab=transaction");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  }, []);

  return (
    <div className="page-content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mt-2 mb-0">Transaction Status</h5>
              </div>
              <div className="card-body">
                <div className="d-block text-center">
                  {transaction ? (
                    transaction.status === "success" ? (
                      <div className="mb-3">
                        <h2 className="mb-3">Thank you for making payment</h2>
                        <MdOutlinePayments size={50} />
                      </div>
                    ) : transaction.status === "cancel" ? (
                      <div className="mb-3">
                        <h2 className="mb-3">
                          Your transaction has been canceled
                        </h2>
                        <MdOutlinePayments size={50} />
                      </div>
                    ) : transaction.status === "expire" ? (
                      <div className="mb-3">
                        <h2 className="mb-3">
                          Your transaction has been expired.
                        </h2>
                        <MdOutlinePayments size={50} />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <h2 className="mb-3">Unknown transaction status</h2>
                        <MdOutlinePayments size={50} />
                      </div>
                    )
                  ) : (
                    <>
                      <Skeleton
                        className="mx-auto mb-3"
                        height={36}
                        width={380}
                      />
                      <Skeleton className="mx-auto" height={50} width={70} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="mb-4">
                  <h5 className="card-title mt-2">Transaction Detail</h5>
                  <hr></hr>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <p className="fw-bold">Transaction Name</p>
                    <p className="fw-bold">Payment Method</p>
                    <p className="fw-bold">Original Price</p>
                    <p className="fw-bold">Tax</p>
                    <p className="fw-bold">Admin Fee</p>
                  </div>
                  <div className="col-sm-6 col-6 text-end">
                    <p className="fw-bold">Topup Credit</p>
                    <p className="fw-bold">
                      {transaction ? formatText(transaction.payment_type) : "-"}
                    </p>
                    <p className="fw-bold">
                      {transaction
                        ? format_number(transaction.nominal.toFixed(0))
                        : 0}
                    </p>
                    <p className="fw-bold">
                      {transaction
                        ? format_number(transaction.tax.toFixed(0))
                        : 0}
                    </p>
                    <p className="fw-bold">0</p>
                  </div>
                  <hr></hr>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <p className="mb-0 fw-bold">Total Payment</p>
                  </div>
                  <div className="col-sm-6 col-6 text-end">
                    <p className="mb-0 fw-bold">
                      {transaction ? format_number(transaction.total) : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* {selectedValue && selectedMethod && ( */}
            {/* <button
            className="btn btn-success btn-lg w-100"
            disabled={!(selectedValue && selectedMethod)}
            onClick={() => submitPayment()}
          >
            Continue Payment
          </button> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionStatus;
