import React, { useContext, useEffect, useState } from "react";
import logosm from "../assets/images/logo-sm.png";
import logolight from "../assets/images/logo-light.png";
import logodark from "../assets/images/logo-dark.png";
import HeaderLanguage from "./component/headerLanguage";
import HeaderNotification from "./component/headerNotification";
import HeaderAccount from "./component/headerAccount";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../provider/cartContext";
import { ThemeContext } from "../provider/themeContext";
import { ModalStartChat } from "../components";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CreditContext } from "../provider/creditContext";
import { format_number } from "../utilities/ext-function";
import HeaderApps from "./component/headerApps";

const Header = () => {
  let location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const { cartItems } = useContext(CartContext);
  const { theme, setTheme, toggleTheme } = useContext(ThemeContext);
  const { credit } = useContext(CreditContext);
  const [sidebarSize, setSidebarSize] = useState("lg");

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      // Masuk fullscreen
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      // Keluar fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (document.fullscreenElement) {
        document.body.classList.add("fullscreen-enable");
      } else {
        document.body.classList.remove("fullscreen-enable");
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
    document.documentElement.setAttribute("data-sidebar-visibility", "show");
    document.documentElement.setAttribute("data-layout-style", "default");
    document.documentElement.setAttribute("data-layout-width", "fluid");
    document.documentElement.setAttribute("data-layout-position", "fixed");

    // Event custom untuk listener global
    window.dispatchEvent(new Event("themeChange"));
  }, [theme]);

  useEffect(() => {
    document.documentElement.setAttribute("data-sidebar-size", sidebarSize);
  }, [sidebarSize]);

  const toggleSidebarSize = () => {
    setSidebarSize((prevSize) => (prevSize === "lg" ? "sm" : "lg"));
  };

  return (
    <div>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <a href="#" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logodark} alt="" height="17" />
                  </span>
                </a>

                <a href="#" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logolight} alt="" height="17" />
                  </span>
                </a>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
                onClick={toggleSidebarSize}
              >
                <span
                  className={`hamburger-icon d-none d-md-block ${
                    sidebarSize === "sm" ? "open" : ""
                  }`}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span
                  className={`hamburger-icon d-block d-sm-none ${
                    sidebarSize === "sm" ? "open" : ""
                  }`}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
              {currentPath === "chat" ? (
                <div className="dropdown topbar-head-dropdown header-item d-none d-sm-flex">
                  <ModalStartChat type={1} />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown d-none topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  id="page-header-search-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-search fs-22"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="ms-1 header-item d-sm-flex">
                <Link
                  to="/credit"
                  className="btn btn-md me-2 btn-primary fs-14"
                >
                  <MdOutlineAccountBalanceWallet
                    size={20}
                    className="me-2"
                    style={{ marginTop: "-1px" }}
                  />
                  Credit: {credit ? format_number(credit) : 0}
                </Link>
              </div>
              <div className="ms-1 header-item d-sm-flex">
                <HeaderLanguage />
              </div>
              <div className="ms-1 header-item d-none d-sm-flex">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  onClick={() => toggleFullScreen()}
                >
                  <i className="bx bx-fullscreen fs-22"></i>
                </button>
              </div>
              <div className="ms-1 header-item d-sm-flex">
                <button
                  type="button"
                  onClick={toggleTheme}
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
                >
                  <i className="bx bx-moon fs-22"></i>
                </button>
              </div>
              <div className="ms-1 header-item d-none d-md-flex">
                <HeaderNotification />
              </div>
              <div className="ms-1 header-item d-none d-md-flex">
                <Link
                  to="/cart"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-cart-alt fs-22"></i>
                  {cartItems.length > 0 ? (
                    <span className="position-absolute topbar-badge badge-cart fs-10 translate-middle badge rounded-pill bg-success">
                      {cartItems.length}
                    </span>
                  ) : null}
                </Link>
              </div>
              <div className="ms-1 header-item d-sm-flex">
                <HeaderApps />
              </div>
              <HeaderAccount />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
