import React, { useContext, useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import upload from "../../assets/images/upload.png";
import { Controller, useForm } from "react-hook-form";
import { ThemeContext } from "../../provider/themeContext";
import { useLanguage } from "../../provider/languageContext";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../../utilities/url";
import storage from "../../services/storage";
import { ModalAddAccessKey, ModalUpdateAccessKey } from "../../components";
import { ToastContainer } from "react-toastify";
import { ToastrContext } from "../../provider/toastrContext";
import {
  GrDocumentLocked,
  GrDocumentPerformance,
  GrDocumentStore,
  GrDocumentTime,
} from "react-icons/gr";
import moment from "moment";
import CryptoJS from "crypto-js";
import { NumericFormat } from "react-number-format";
import { Spinner } from "react-bootstrap";

var $ = require("jquery");

const Settings = () => {
  const { theme } = useContext(ThemeContext);
  const { showSuccess, showError } = useContext(ToastrContext);
  const { messagesLang } = useLanguage();
  const simpleBarRef = useRef(null);
  const tableRef = useRef(null);
  const tableRef2 = useRef(null);
  const tableRef3 = useRef(null);
  const [imagePreview, setImagePreview] = useState(upload);
  const [isMobileChatActive, setIsMobileChatActive] = useState(false);
  const [loadItem, setLoadItem] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [dataForms, setDataForms] = useState([]);
  const [dataFormsValue, setDataFormsValue] = useState([]);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const menus = [
    {
      name: "Knowledge Access",
      icon: "key",
    },
    {
      name: "Knowledge Training",
      icon: "training",
    },
    {
      name: "Knowledge Retrieval",
      icon: "retrieval",
    },
    {
      name: "Knowledge Archive",
      icon: "archive",
    },
  ];
  const [activeMenu, setActiveMenu] = useState(menus[0].name);

  const accessKeyDatatable = () => {
    const newTable = $("#access-key-table").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: "true",
      language: {
        processing: `<div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden"></span>
          </div>`,
      },
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-knowledge-access-key",
        type: "post",
        headers: {
          Authorization: "Bearer " + storage.get("access_token"),
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              if (key !== "lang") {
                storage.remove("access_token");
                localStorage.removeItem(key);
              }
            }
            window.location.replace("login");
          }
        },
      },
      columns: [
        {
          data: "idt_knowledge_access_key",
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
        },
        {
          data: "label",
          render: function (data) {
            return data ? data : "-";
          },
        },
        {
          data: "title",
        },
        { data: "assistant" },
        {
          data: "access_key",
          render: function (data, type, row) {
            return `<input type="text" class="form-control apikey-value" readonly="" value="${data}">`;
          },
        },
        { data: "active" },
        {
          data: "idt_knowledge_access_key",
          render: function (data, type, row) {
            return `<button class="access-btn btn btn-info btn-sm me-1" data-id='${JSON.stringify(
              row
            )}'><i class="mdi mdi-pencil-outline"></i></button><button class="access-del-btn btn btn-danger btn-sm" data-id='${data}'><i class="mdi mdi-trash-can-outline"></i></button>`;
          },
          orderable: false,
        },
      ],
      order: [[0, "desc"]],
      createdRow: function (row) {
        $(row).find("td").eq(5).addClass("text-center");
      },
      initComplete: function (settings, json) {
        const searchInput = $("div.dataTables_filter input");
        searchInput.off();
        searchInput.on("keypress", function (e) {
          if (e.which === 13) {
            // Check if Enter key is pressed
            newTable.search(this.value).draw();
          }
        });
      },
    });

    $("#access-key-table tbody").on("click", ".access-btn", function () {
      const dataInfo = $(this).data("id");
      handleShow(dataInfo);
    });

    $("#access-key-table tbody").on("click", ".access-del-btn", function () {
      const id = $(this).data("id");
      axios
        .post(
          API_URL + "remove-knowledge-access-key",
          { idt_knowledge_access_key: id },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const table = $("#access-key-table").DataTable();
            table.ajax.reload();
          } else {
            showError(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    });

    return newTable;
  };

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");

  const handleShow = (data) => {
    setModalData(data);
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const archiveDatatable = () => {
    const newTable = $("#archive-table").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: "true",
      language: {
        processing: `<div class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden"></span>
        </div>`,
      },
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-knowledge-archive",
        type: "post",
        headers: {
          Authorization: "Bearer " + storage.get("access_token"),
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              if (key !== "lang") {
                storage.remove("access_token");
                localStorage.removeItem(key);
              }
            }
            window.location.replace("login");
          }
        },
      },
      columns: [
        {
          data: "idt_knowledge_archive",
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
        },
        { data: "title" },
        {
          data: "type",
        },
        {
          data: "ts_archive",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        {
          data: "idt_knowledge_archive",
          render: function (data, type, row) {
            return `<button class="re-archive-btn btn btn-primary btn-sm" data-id="${data}">Re-Archive</button>`;
          },
          orderable: false,
        },
      ],
      order: [[0, "desc"]],
      createdRow: function (row) {
        $(row).find("td").eq(4).addClass("text-center");
      },
      initComplete: function (settings, json) {
        const searchInput = $("div.dataTables_filter input");
        searchInput.off();
        searchInput.on("keypress", function (e) {
          if (e.which === 13) {
            // Check if Enter key is pressed
            newTable.search(this.value).draw();
          }
        });
      },
    });

    // Event listener for Re-Archive button
    $("#archive-table tbody").on("click", "button.re-archive-btn", function () {
      const data = $(this).data("id");
      handleReArchive(data);
    });

    return newTable;
  };

  const handleReArchive = (data) => {
    axios
      .post(
        API_URL + "re-archive",
        { idt_knowledge_archive: data },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const table = $("#archive-table").DataTable();
          table.ajax.reload();
        } else {
          showError(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activeMenu === "Knowledge Access") {
      // $("#access-key-table").DataTable().clear().destroy();
      accessKeyDatatable();
    } else if (activeMenu === "Knowledge Archive") {
      // $("#archive-table").DataTable().clear().destroy();
      archiveDatatable();
    }
  }, [activeMenu]);

  const toggleMobileChatClass = async (item) => {
    if (activeMenu !== item.name) {
      scrollToTop();
      if (
        item.name === "Knowledge Training" ||
        item.name === "Knowledge Retrieval"
      ) {
        reset();
        axios
          .get(API_URL_CORE + "user/settings/form?user_id=" + usr.idt_user)
          .then((response) => {
            if (response.data.errormsg === "OK") {
              setDataForms(response.data.data.forms);
              setDataFormsValue(response.data.data.values);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setActiveMenu(item.name);
      setIsMobileChatActive(true);
      $("#access-key-table").DataTable().clear().destroy();
      $("#archive-table").DataTable().clear().destroy();
    }
  };

  const scrollToTop = () => {
    if (simpleBarRef.current) {
      simpleBarRef.current
        .getScrollElement()
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmitKT = (data) => {
    const convertedArray = Object.keys(data).map((key) => {
      const value = data[key];
      let value_string;
      let value_int;
      if (typeof value === "string") {
        value_string = value;
        value_int = null;
      } else if (typeof value === "number") {
        if (Number.isInteger(value)) {
          value_string = "";
          value_int = value;
        } else {
          value_string = "";
          value_int = value;
        }
      } else {
        value_string = "";
        value_int = null;
      }

      return {
        parameter_id: key,
        value_string: value_string,
        value_number: value_int,
      };
    });
    setLoading(true);
    axios
      .put(API_URL_CORE + "user/settings", {
        user_id: usr.idt_user,
        settings: convertedArray,
      })
      .then((response) => {
        if (response.data.errormsg === "OK") {
          showSuccess("Success to update");
          setLoading(false);
        } else {
          showError("Failed to update");
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  const onReload = () => {
    const table = $("#access-key-table").DataTable();
    table.ajax.reload();
  };

  const renderFormsTraining = () => {
    let previousSubsectionCaption = "";

    const getParameterValue = (parameterValue) => {
      try {
        return JSON.parse(parameterValue);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return {};
      }
    };

    return dataForms.map((item, index) => {
      if (item.MenuID === "train") {
        // Cek apakah SubsectionCaption sama dengan sebelumnya
        const showSubsectionCaption =
          item.SubsectionCaption.String !== previousSubsectionCaption;

        // Simpan SubsectionCaption saat ini untuk perbandingan berikutnya
        previousSubsectionCaption = item.SubsectionCaption.String;

        // Parse parameterValue
        const parameterData = getParameterValue(item.ParameterValue);

        const defaultValue =
          parameterData.type === "float"
            ? parameterData.default_value.float
            : parameterData.type === "integer"
            ? parameterData.default_value.integer
            : parameterData.type === "text"
            ? parameterData.default_value.text
            : parameterData.type === "textarea"
            ? parameterData.default_value.textarea
            : null;

        let dataValue = null;
        if (dataFormsValue && dataFormsValue.length > 0) {
          const param = dataFormsValue.find(
            (form) => form.Param === item.ParameterID
          );
          if (param) {
            dataValue =
              parameterData.type === "float"
                ? param.ValueNumber.Float64
                : parameterData.type === "integer"
                ? param.ValueNumber.Float64
                : parameterData.type === "text"
                ? param.ValueString.String
                : parameterData.type === "textarea"
                ? param.ValueString.String
                : parameterData.type === "option"
                ? param.ValueString.String
                : null;
          }
        }

        // Ambil nilai saat ini dari watch atau gunakan default value
        const currentValue =
          watch(item.ParameterID) !== undefined
            ? watch(item.ParameterID)
            : dataValue
            ? dataValue
            : defaultValue; // Mengambil nilai saat ini

        return (
          <React.Fragment key={index}>
            {showSubsectionCaption && item.SubsectionCaption.String !== "" && (
              <hr className="mt-2 mb-2" />
            )}
            <div className="row mb-3">
              {showSubsectionCaption &&
                item.SubsectionCaption.String !== "" && (
                  <p className="fw-bold">[{item.SubsectionCaption.String}]</p>
                )}
              <div className="col-lg-2">
                <div className="mt-2 mb-2">
                  <label
                    htmlFor={item.ParameterID}
                    className="form-label w-100"
                  >
                    {item.ParameterCaption}{" "}
                    {(parameterData.type === "integer" ||
                      parameterData.type === "float") && (
                      <>
                        [{parameterData.range.min_val} -{" "}
                        {parameterData.range.max_val}]
                      </>
                    )}
                    <span className="text-danger">*</span>
                  </label>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                {parameterData.type === "option" &&
                parameterData.default_value &&
                parameterData.default_value.option ? (
                  <select
                    name={item.ParameterID}
                    className="form-select"
                    {...register(item.ParameterID, { required: true })}
                    value={currentValue}
                    onChange={(e) => {
                      setValue(item.ParameterID, e.target.value);
                      clearErrors(item.ParameterID);
                    }}
                  >
                    {parameterData.default_value.option.map((opt) => (
                      <option
                        key={opt.id}
                        value={opt.id}
                        selected={opt.default && "selected"}
                      >
                        {opt.caption}
                      </option>
                    ))}
                  </select>
                ) : parameterData.type === "textarea" ? (
                  <textarea
                    name={item.ParameterID}
                    className="form-control"
                    {...register(item.ParameterID, {
                      required: true,
                    })}
                    value={currentValue}
                    onChange={(e) => {
                      setValue(item.ParameterID, e.target.value);
                      clearErrors(item.ParameterID);
                    }}
                  />
                ) : parameterData.type === "integer" ||
                  parameterData.type === "float" ? (
                  <Controller
                    name={item.ParameterID}
                    defaultValue={currentValue}
                    control={control}
                    rules={{
                      required: true,
                      min: parameterData.range.min_val,
                      max: parameterData.range.max_val,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NumericFormat
                        value={value}
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={(values) => {
                          const numericValue = values.floatValue;
                          onChange(numericValue);
                          clearErrors(item.ParameterID);
                        }}
                        onBlur={onBlur}
                        className="form-control"
                      />
                    )}
                  />
                ) : (
                  <input
                    type="text"
                    name={item.ParameterID}
                    className="form-control"
                    {...register(item.ParameterID, { required: true })}
                    value={currentValue}
                    onChange={(e) => {
                      setValue(item.ParameterID, e.target.value);
                      clearErrors(item.ParameterID);
                    }}
                  />
                )}
                {errors[item.ParameterID] &&
                  errors[item.ParameterID].type === "required" && (
                    <p className="errorMsg text-danger fs-7">
                      {item.ParameterCaption} is required.
                    </p>
                  )}
                {errors[item.ParameterID] &&
                  errors[item.ParameterID].type === "min" && (
                    <p className="errorMsg text-danger fs-7">
                      Minimum value is {parameterData.range.min_val}
                    </p>
                  )}
                {errors[item.ParameterID] &&
                  errors[item.ParameterID].type === "max" && (
                    <p className="errorMsg text-danger fs-7">
                      Maximum value is {parameterData.range.max_val}
                    </p>
                  )}
              </div>
            </div>
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const renderFormsRetrieval = () => {
    let previousSubsectionCaption = "";

    const getParameterValue = (parameterValue) => {
      try {
        return JSON.parse(parameterValue);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return {};
      }
    };

    return dataForms.map((item, index) => {
      if (item.MenuID === "retrieval") {
        // Cek apakah SubsectionCaption sama dengan sebelumnya
        const showSubsectionCaption =
          item.SubsectionCaption.String !== previousSubsectionCaption;

        // Simpan SubsectionCaption saat ini untuk perbandingan berikutnya
        previousSubsectionCaption = item.SubsectionCaption.String;

        // Parse parameterValue
        const parameterData = getParameterValue(item.ParameterValue);

        const defaultValue =
          parameterData.type === "float"
            ? parameterData.default_value.float
            : parameterData.type === "integer"
            ? parameterData.default_value.integer
            : parameterData.type === "text"
            ? parameterData.default_value.text
            : parameterData.type === "textarea"
            ? parameterData.default_value.textarea
            : null;

        let dataValue = null;
        if (dataFormsValue && dataFormsValue.length > 0) {
          const param = dataFormsValue.find(
            (form) => form.Param === item.ParameterID
          );
          if (param) {
            dataValue =
              parameterData.type === "float"
                ? param.ValueNumber.Float64
                : parameterData.type === "integer"
                ? param.ValueNumber.Float64
                : parameterData.type === "text"
                ? param.ValueString.String
                : parameterData.type === "textarea"
                ? param.ValueString.String
                : parameterData.type === "option"
                ? param.ValueString.String
                : null;
          }
        }

        // Ambil nilai saat ini dari watch atau gunakan default value
        const currentValue =
          watch(item.ParameterID) !== undefined
            ? watch(item.ParameterID)
            : dataValue
            ? dataValue
            : defaultValue; // Mengambil nilai saat ini

        return (
          <React.Fragment key={index}>
            {showSubsectionCaption && item.SubsectionCaption.String !== "" && (
              <hr className="mt-2 mb-2" />
            )}
            <div className="row mb-3">
              {showSubsectionCaption &&
                item.SubsectionCaption.String !== "" && (
                  <p className="fw-bold">[{item.SubsectionCaption.String}]</p>
                )}
              <div className="col-lg-2">
                <div className="mt-2 mb-2">
                  <label
                    htmlFor={item.ParameterID}
                    className="form-label w-100"
                  >
                    {item.ParameterCaption}{" "}
                    {(parameterData.type === "integer" ||
                      parameterData.type === "float") && (
                      <>
                        [{parameterData.range.min_val} -{" "}
                        {parameterData.range.max_val}]
                      </>
                    )}
                    <span className="text-danger">*</span>
                  </label>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                {parameterData.type === "option" &&
                parameterData.default_value &&
                parameterData.default_value.option ? (
                  <select
                    name={item.ParameterID}
                    className="form-select"
                    {...register(item.ParameterID, { required: true })}
                    value={currentValue}
                    onChange={(e) => {
                      setValue(item.ParameterID, e.target.value);
                      clearErrors(item.ParameterID);
                    }}
                  >
                    {parameterData.default_value.option.map((opt) => (
                      <option key={opt.id} value={opt.id}>
                        {opt.caption}
                      </option>
                    ))}
                  </select>
                ) : parameterData.type === "textarea" ? (
                  <textarea
                    name={item.ParameterID}
                    className="form-control"
                    {...register(item.ParameterID, { required: true })}
                    value={currentValue}
                    onChange={(e) => {
                      setValue(item.ParameterID, e.target.value);
                      clearErrors(item.ParameterID);
                    }}
                  />
                ) : parameterData.type === "integer" ||
                  parameterData.type === "float" ? (
                  <Controller
                    name={item.ParameterID}
                    defaultValue={currentValue}
                    control={control}
                    rules={{
                      required: true,
                      min: parameterData.range.min_val,
                      max: parameterData.range.max_val,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NumericFormat
                        value={value}
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={(values) => {
                          const numericValue = values.floatValue;
                          onChange(numericValue);
                          clearErrors(item.ParameterID);
                        }}
                        onBlur={onBlur}
                        className="form-control"
                      />
                    )}
                  />
                ) : (
                  <input
                    type="text"
                    name={item.ParameterID}
                    className="form-control"
                    {...register(item.ParameterID, { required: true })}
                    value={currentValue}
                    onChange={(e) => {
                      setValue(item.ParameterID, e.target.value);
                      clearErrors(item.ParameterID);
                    }}
                  />
                )}
                {errors[item.ParameterID] &&
                  errors[item.ParameterID].type === "required" && (
                    <p className="errorMsg text-danger fs-7">
                      {item.ParameterCaption} is required.
                    </p>
                  )}
                {errors[item.ParameterID] &&
                  errors[item.ParameterID].type === "min" && (
                    <p className="errorMsg text-danger fs-7">
                      Minimum value is {parameterData.range.min_val}
                    </p>
                  )}
                {errors[item.ParameterID] &&
                  errors[item.ParameterID].type === "max" && (
                    <p className="errorMsg text-danger fs-7">
                      Maximum value is {parameterData.range.max_val}
                    </p>
                  )}
              </div>
            </div>
          </React.Fragment>
        );
      }
      return null;
    });
  };

  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div className="container-fluid">
        <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
          <div className="chat-leftsidebar">
            <div className="p-4 mt-md-2 d-flex flex-column h-100">
              <h5 className="mb-1">Settings</h5>
              <ul
                className="nav nav-tabs nav-tabs-custom nav-success nav-justified"
                role="tablist"
              >
                <li className="nav-item">
                  <a className="nav-link active pt-0" role="tab">
                    {""}
                  </a>
                </li>
              </ul>
              <SimpleBar className="chat-room-list pt-2">
                <div className="chat-message-list">
                  <ul
                    className="list-unstyled chat-list chat-user-list"
                    id="userList"
                  >
                    {menus.map((item, index) => (
                      <li
                        data-name="direct-message"
                        key={index}
                        className={
                          item.name === activeMenu ||
                          (loadItem === true && index === true)
                            ? "active"
                            : ""
                        }
                      >
                        <a className="chats rounded-2 p-2">
                          <div
                            className="d-flex align-items-center"
                            onClick={() => toggleMobileChatClass(item)}
                          >
                            <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                              <div className="avatar-xxs">
                                {item.icon === "key" && (
                                  <GrDocumentLocked size={17} />
                                )}
                                {item.icon === "training" && (
                                  <GrDocumentPerformance size={17} />
                                )}
                                {item.icon === "retrieval" && (
                                  <GrDocumentTime size={17} />
                                )}
                                {item.icon === "archive" && (
                                  <GrDocumentStore size={17} />
                                )}
                              </div>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <p
                                className="texted text-truncate fs-14"
                                style={{ marginBottom: "-3px" }}
                              >
                                {item.name}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </SimpleBar>
            </div>
          </div>
          {activeMenu === "Knowledge Access" ? (
            <div
              className={
                isMobileChatActive
                  ? "personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "personal-assistant-content w-100 p-3 py-0"
              }
            >
              <SimpleBar
                ref={simpleBarRef}
                className="mt-2 mx-n4 px-4"
                style={{ height: "calc(100vh - 150px)" }}
              >
                <div className="mx-n3 pt-4 px-4">
                  <div id="folder-list" className="mb-2">
                    <div className="row g-2 mb-3">
                      <div className="d-flex justify-content-beetwen">
                        <div className="col pb-3">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="fs-16 mb-0">Knowledge Access</h5>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ModalAddAccessKey onReload={onReload} />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive mb-1">
                          <table
                            id="access-key-table"
                            ref={tableRef}
                            className="table table-bordered w-100"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>NO</th>
                                <th>LABEL</th>
                                <th>KNOWLEDGE / COLLECTION</th>
                                <th>ASSISTANT NAME</th>
                                <th>ACCESS KEY</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SimpleBar>
            </div>
          ) : activeMenu === "Knowledge Training" ? (
            <div
              className={
                isMobileChatActive
                  ? "personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "personal-assistant-content w-100 p-3 py-0"
              }
            >
              <SimpleBar
                ref={simpleBarRef}
                className="mt-2 mx-n4 px-4"
                style={{ height: "calc(100vh - 150px)" }}
              >
                <div className="mx-n3 pt-4 px-4">
                  <div id="folder-list" className="mb-2">
                    <div className="row justify-content-beetwen g-2 mb-3">
                      <div className="col pb-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="fs-16 mb-0">Knowledge Training</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <form onSubmit={handleSubmit(onSubmitKT)}>
                          {renderFormsTraining()}
                          <hr />
                          <button
                            type="submit"
                            className="btn btn-success w-sm"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  className="flex-shrink-0"
                                  animation="border"
                                  size="sm"
                                />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </SimpleBar>
            </div>
          ) : activeMenu === "Knowledge Retrieval" ? (
            <div
              className={
                isMobileChatActive
                  ? "personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "personal-assistant-content w-100 p-3 py-0"
              }
            >
              <SimpleBar
                ref={simpleBarRef}
                className="mt-2 mx-n4 px-4"
                style={{ height: "calc(100vh - 150px)" }}
              >
                <div className="mx-n3 pt-4 px-4">
                  <div id="folder-list" className="mb-2">
                    <div className="row justify-content-beetwen g-2 mb-3">
                      <div className="col pb-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="fs-16 mb-0">Knowledge Retrieval</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <form onSubmit={handleSubmit(onSubmitKT)}>
                          {renderFormsRetrieval()}
                          <hr />
                          <button
                            type="submit"
                            className="btn btn-success w-sm"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  className="flex-shrink-0"
                                  animation="border"
                                  size="sm"
                                />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </SimpleBar>
            </div>
          ) : activeMenu === "Knowledge Archive" ? (
            <div
              className={
                isMobileChatActive
                  ? "personal-assistant-content w-100 p-3 py-0 user-chat-show"
                  : "personal-assistant-content w-100 p-3 py-0"
              }
            >
              <SimpleBar
                ref={simpleBarRef}
                className="mt-2 mx-n4 px-4"
                style={{ height: "calc(100vh - 150px)" }}
              >
                <div className="mx-n3 pt-4 px-4">
                  <div id="folder-list" className="mb-2">
                    <div className="row justify-content-beetwen g-2 mb-3">
                      <div className="col pb-3">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="fs-16 mb-0">Knowledge Archive</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive mb-1">
                          <table
                            id="archive-table"
                            ref={tableRef3}
                            className="table table-bordered w-100"
                          >
                            <thead className="table-light">
                              <tr>
                                <th>NO</th>
                                <th>NAME</th>
                                <th>TYPE</th>
                                <th>DATE</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SimpleBar>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Modal External */}
        <ModalUpdateAccessKey
          show={modalShow}
          handleClose={handleClose}
          data={modalData}
          onReload={onReload}
        />
      </div>
    </div>
  );
};

export default Settings;
