import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logosm from "../assets/images/logo-sm.png";
import logolight from "../assets/images/logo-light.png";
import logodark from "../assets/images/logo-dark.png";
import SimpleBar from "simplebar-react";
import { RxCross2 } from "react-icons/rx";
import {
  MdOutlineChat,
  MdOutlineLocalLibrary,
  MdOutlineSpaceDashboard,
  MdOutlineTravelExplore,
} from "react-icons/md";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { useLanguage } from "../provider/languageContext";
import { FaUserAstronaut } from "react-icons/fa";

const Sidebar = () => {
  let location = useLocation();
  const { messagesLang } = useLanguage();
  const currentPath = location.pathname.split("/")[1];
  const [isVSE, setIsVSE] = useState(false);

  const toggleVSE = () => {
    if (window.innerWidth <= 678) {
      setIsVSE(!isVSE);
      document.body.classList.toggle("vertical-sidebar-enable");
    }
  };

  return (
    <div>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <a href="#" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logosm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logodark} alt="" height="40" />
            </span>
          </a>
          {/* Light Logo */}
          <a href="#" className="logo logo-light">
            <span className="logo-sm">
              <img src={logosm} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logolight} alt="" height="40" />
            </span>
          </a>

          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="logo logo-light" style={{ lineHeight: "20px" }}>
              <span className="logo-sm">
                <Link
                  to="/user-profile?tab=subscription"
                  className="btn rounded-pill btn-success waves-effect waves-light"
                >
                  <HiOutlineDesktopComputer className="text-white" size={20} />
                </Link>
              </span>
              <span className="logo-lg">
                <Link
                  to="/user-profile?tab=subscription"
                  className="btn rounded-pill btn-success waves-effect waves-light"
                >
                  <HiOutlineDesktopComputer
                    className="text-white me-2"
                    size={20}
                  />
                  <span data-key="t-widgets" className="fs-12 text-white">
                    {/* {messagesLang.upgrade_account} */}
                    My Subscription
                  </span>
                </Link>
              </span>
            </div>
          </div>
        </div>

        <SimpleBar className="h-100">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav custome" id="navbar-nav">
              <li className="menu-title d-flex justify-content-between">
                <span data-key="t-menu">Menu</span>
                <button
                  onClick={() => toggleVSE()}
                  className="btn btn-sm text-sm-grey px-3 vertical-menu-btn d-block d-sm-none"
                >
                  {/* <BsArrowLeft strokeWidth="0.5" size={24} /> */}
                  <RxCross2 strokeWidth="0.5" size={20} />
                </button>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link menu-link"
                  href="#sidebarDashboards"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <i className="ri-dashboard-2-line"></i>{" "}
                  <span data-key="t-dashboards">Dashboards</span>
                </a>
                <div className="collapse menu-dropdown" id="sidebarDashboards">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <a
                        href="dashboard-analytics.html"
                        className="nav-link"
                        data-key="t-analytics"
                      >
                        {" "}
                        Analytics{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </li> */}
              <li
                className={
                  currentPath == "home"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/home"
                  onClick={() => toggleVSE()}
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <MdOutlineSpaceDashboard size={24} />
                  <span data-key="t-widgets">{messagesLang.home}</span>
                </NavLink>
              </li>
              <li
                className={
                  currentPath == "chat"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/chat"
                  onClick={() => toggleVSE()}
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <MdOutlineChat size={24} />
                  <span data-key="t-widgets">Chat</span>
                </NavLink>
              </li>
              <li
                className={
                  currentPath == "my-library"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/my-library"
                  onClick={() => toggleVSE()}
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <MdOutlineLocalLibrary size={24} />
                  <span data-key="t-widgets">{messagesLang.my_library}</span>
                </NavLink>
              </li>
              <li
                className={
                  currentPath == "knowledge"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/knowledge"
                  onClick={() => toggleVSE()}
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <MdOutlineTravelExplore size={24} />
                  <span data-key="t-widgets">Knowledge Store</span>
                </NavLink>
              </li>
              <li
                className={
                  currentPath == "personal-assistant"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/personal-assistant"
                  onClick={() => toggleVSE()}
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <FaUserAstronaut size={24} />
                  <span data-key="t-widgets">Personal Assistant</span>
                </NavLink>
              </li>
              {/* <li
                className={
                  currentPath == "archive"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/archive"
                  onClick={() => {
                    if (window.innerWidth <= 678) {
                      toggleVSE();
                    }
                  }}
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <MdOutlineArchive size={24} />
                  <span data-key="t-widgets">{messagesLang.archive}</span>
                </NavLink>
              </li> */}
              {/* <li
                className={
                  currentPath == "user"
                    ? "nav-item nav-custome active"
                    : "nav-item nav-custome"
                }
              >
                <NavLink
                  to="/user"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link menu-link active"
                      : "nav-link menu-link"
                  }
                >
                  <i className="ri-honour-line"></i>{" "}
                  <span data-key="t-widgets">User</span>
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <a
                  className="nav-link menu-link"
                  href="#sidebarMultilevel"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMultilevel"
                >
                  <i className="ri-share-line"></i>{" "}
                  <span data-key="t-multi-level">Multi Level</span>
                </a>
                <div className="collapse menu-dropdown" id="sidebarMultilevel">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <a href="#" className="nav-link" data-key="t-level-1.1">
                        {" "}
                        Level 1.1{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#sidebarAccount"
                        className="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarAccount"
                        data-key="t-level-1.2"
                      >
                        {" "}
                        Level 1.2
                      </a>
                      <div
                        className="collapse menu-dropdown"
                        id="sidebarAccount"
                      >
                        <ul className="nav nav-sm flex-column">
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link"
                              data-key="t-level-2.1"
                            >
                              {" "}
                              Level 2.1{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#sidebarCrm"
                              className="nav-link"
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls="sidebarCrm"
                              data-key="t-level-2.2"
                            >
                              {" "}
                              Level 2.2
                            </a>
                            <div
                              className="collapse menu-dropdown"
                              id="sidebarCrm"
                            >
                              <ul className="nav nav-sm flex-column">
                                <li className="nav-item">
                                  <a
                                    href="#"
                                    className="nav-link"
                                    data-key="t-level-3.1"
                                  >
                                    {" "}
                                    Level 3.1
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    href="#"
                                    className="nav-link"
                                    data-key="t-level-3.2"
                                  >
                                    {" "}
                                    Level 3.2
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li> 
                  </ul>
                </div>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </div>
  );
};

export default Sidebar;
