import axios from "axios";
import { API_URL } from "../utilities/url";

class AuthService {
  auth() {
    if (
      !localStorage.getItem("user") ||
      !localStorage.getItem("access_token")
    ) {
      return false;
    } else {
      return true;
    }
  }
  logout() {
    axios
      .post(API_URL + "logout")
      .then((response) => {
        if (response.data.success === true) {
          localStorage.clear();
          window.location.replace("/login");
        }
      })
      .catch((error) => {
        localStorage.clear();
        window.location.replace("/login");
      });
  }
}

export default new AuthService();
