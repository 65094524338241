import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import authHeader from "../services/auth.header";

export const CreditContext = createContext();

export const CreditProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [credit, setCredit] = useState(null);
  const [compact, setCompact] = useState(false);
  const tabValue = searchParams.get("tab");

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = () => {
    axios
      .post(
        API_URL + "get-user-balance",
        {},
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setCredit(
            response.data.data.reduce((sum, item) => {
              return sum + (item.balance || 0);
            }, 0)
          );
        }
      })
      .catch((error) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  return (
    <CreditContext.Provider
      value={{
        credit,
        setCredit,
        getBalance,
      }}
    >
      {children}
    </CreditContext.Provider>
  );
};
