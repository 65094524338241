import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import qris from "../../assets/images/payment/qris.png";
import shopay from "../../assets/images/payment/shopay.png";
import ovo from "../../assets/images/payment/ovo.png";
import gopay from "../../assets/images/payment/gopay.png";
import dana from "../../assets/images/payment/dana.png";
import bni from "../../assets/images/payment/BNI.png";
import bca from "../../assets/images/payment/BCA.png";
import bri from "../../assets/images/payment/BRI.png";
import mandiri from "../../assets/images/payment/mandiri.png";
import payment_img from "../../assets/images/payment.png";
import { formatText, format_number } from "../../utilities/ext-function";
import authService from "../../services/auth.service";
import axios from "axios";
import authHeader from "../../services/auth.header";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import Skeleton from "react-loading-skeleton";
import { Spinner } from "react-bootstrap";
// import { SubscriptionContext } from "../../provider/subscriptionContext";
import { CreditContext } from "../../provider/creditContext";

var $ = require("jquery");

const TransactionPayment = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { getBalance } = useContext(CreditContext);
  // const { getSubs } = useContext(SubscriptionContext);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const items = [
    { value: "qris", image: qris },
    { value: "shopee_pay", image: shopay },
    { value: "gopay", image: gopay },
    { value: "ovo", image: ovo },
    { value: "dana", image: dana },
    { value: "bni", image: bni },
    { value: "bca", image: bca },
    { value: "bri", image: bri },
    { value: "mandiri", image: mandiri },
  ];

  useEffect(() => {
    axios
      .post(
        API_URL + "get-transaction",
        {
          oid: orderId,
          status: ["pending"],
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setTransaction(response.data.data);
          const selectedItem = items.find(
            (item) => item.value === response.data.data.payment_channel
          );
          if (selectedItem) {
            setSelectedPayment(selectedItem);
          }
        } else {
          navigate("/account?tab=transaction");
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  }, []);

  const checkStatus = () => {
    setLoading(true);
    axios
      .post(
        API_URL + "get-transaction",
        {
          oid: orderId,
          status: ["success", "expire", "cancel"],
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.status === "success") {
            getBalance();
            // getSubs();
            navigate("/transaction-status/" + orderId);
          } else {
            navigate("/account?tab=transaction");
          }
        } else {
          toast.error("Payment is still in pending process.", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  };

  const cancelStatus = () => {
    setLoading2(true);
    axios
      .post(
        API_URL + "cancel-transaction",
        {
          oid: orderId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          navigate("/account?tab=transaction");
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading2(false);
      })
      .catch((error) => {
        setLoading2(false);
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  };

  return (
    <div className="page-content">
      <div className="container-fluid p-0">
        <ToastContainer />
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-block">
                  {transaction ? (
                    <h5 className="card-title mt-2 mb-0">
                      Make Payment <b>{transaction.order_id}</b> using the
                      barcode scan below.
                    </h5>
                  ) : (
                    <Skeleton width={400} height={20} />
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="d-block text-center mb-3">
                  {transaction ? (
                    transaction.payment_acc_number ? (
                      <div className="mt-5">
                        {selectedPayment ? (
                          <img
                            src={selectedPayment.image || null}
                            className="img-responsive mb-5"
                            width={120}
                            height={35}
                          />
                        ) : (
                          <Skeleton className="mb-2" width={100} height={35} />
                        )}

                        {transaction ? (
                          <>
                            <p className="fw-bold fs-16">VA Number:</p>
                            <p className="fw-bold fs-20">
                              {transaction.payment_acc_number}
                            </p>
                          </>
                        ) : (
                          <Skeleton width={260} height={260} />
                        )}
                      </div>
                    ) : transaction.payment_bill_code ? (
                      <div>
                        {selectedPayment ? (
                          <img
                            src={selectedPayment.image || null}
                            className="img-responsive mb-5"
                            width={120}
                            height={35}
                          />
                        ) : (
                          <Skeleton className="mb-2" width={100} height={35} />
                        )}

                        {transaction ? (
                          <div className="d-flex flex-column w-50 mx-auto">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-bold fs-16">
                                Biller Code:
                              </span>
                              <span className="fs-16">
                                {transaction.payment_bill_code}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <span className="fw-bold fs-16">Bill Key:</span>
                              <span className="fs-16">
                                {transaction.payment_bill_key}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <Skeleton width={260} height={260} />
                        )}
                      </div>
                    ) : (
                      <div>
                        {selectedPayment ? (
                          <img
                            src={selectedPayment.image || null}
                            className="img-responsive d-block mx-auto"
                            width={120}
                            height={35}
                          />
                        ) : (
                          <Skeleton className="mb-2" width={100} height={35} />
                        )}

                        {transaction ? (
                          <img
                            src={transaction.payment_qr || null}
                            className="img-responsive"
                            width={300}
                            height={300}
                          />
                        ) : (
                          <Skeleton width={260} height={260} />
                        )}
                      </div>
                    )
                  ) : null}
                </div>
                <hr className="line" />
                <div className="d-block fw-bold text-center mb-3">
                  <p className="fs-16">
                    Please complete the transaction by the specified deadline.
                  </p>
                  {transaction ? (
                    <p className="fs-14" style={{ color: "#d9534f" }}>
                      {moment(transaction.ts_payment_expiry).format(
                        "DD MMMM YYYY, HH:mm:ss"
                      )}
                    </p>
                  ) : (
                    <Skeleton width={200} height={20} className="mb-3" />
                  )}
                  <p className="fs-14">
                    The payment status will be canceled if it exceeds the
                    specified time limit.
                  </p>
                </div>
                <hr className="line" />
                <div className="d-flex fw-bold justify-content-center">
                  <button
                    className="btn btn-md btn-success me-2"
                    onClick={() => checkStatus()}
                    disabled={loading}
                  >
                    {loading ? (
                      <div style={{ width: "90px" }}>
                        <Spinner className="spinner-border-sm" />
                      </div>
                    ) : (
                      "Check Status"
                    )}
                  </button>
                  <button
                    className="btn btn-md btn-danger"
                    onClick={() => cancelStatus()}
                    disabled={loading2}
                  >
                    {loading2 ? (
                      <div style={{ width: "110px" }}>
                        <Spinner className="spinner-border-sm" />
                      </div>
                    ) : (
                      "Cancel Payment"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="mb-4">
                  <h5 className="card-title mt-2">Transaction Detail</h5>
                  <hr></hr>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <p className="fw-bold">Transaction Name</p>
                    <p className="fw-bold">Payment Method</p>
                    <p className="fw-bold">Original Price</p>
                    <p className="fw-bold">Tax</p>
                    <p className="fw-bold">Admin Fee</p>
                  </div>
                  <div className="col-sm-6 col-6 text-end">
                    <p className="fw-bold">Topup Credit</p>
                    <p className="fw-bold">
                      {transaction ? formatText(transaction.payment_type) : "-"}
                    </p>
                    <p className="fw-bold">
                      {transaction
                        ? format_number(transaction.nominal.toFixed(0))
                        : 0}
                    </p>
                    <p className="fw-bold">
                      {transaction
                        ? format_number(transaction.tax.toFixed(0))
                        : 0}
                    </p>
                    <p className="fw-bold">0</p>
                  </div>
                  <hr></hr>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="mb-0 fw-bold">Total Payment</p>
                  </div>
                  <div className="col-md-6 text-end">
                    <p className="mb-0 fw-bold">
                      {transaction ? format_number(transaction.total) : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* {selectedValue && selectedMethod && ( */}
            {/* <button
            className="btn btn-success btn-lg w-100"
            disabled={!(selectedValue && selectedMethod)}
            onClick={() => submitPayment()}
          >
            Continue Payment
          </button> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionPayment;
